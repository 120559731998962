<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <h2>Do you often get sick?</h2>
          <v-divider></v-divider>
          <v-radio-group v-model="Sick" row>
            <v-radio label="Yes" value="Yes"></v-radio>
            <v-radio label="No" value="No"></v-radio>
            <v-radio label="Always" value="Always"></v-radio>
            <v-radio label="Seldom" value="Seldom"></v-radio>
            <v-radio label="Sometimes" value="Sometimes"></v-radio>
            <v-radio label="Never" value="Never"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <h2>Usual health problems (You can check more than one)</h2>
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-checkbox
            v-model="usual1"
            label="Dysmenorrhea"
            color="primary"
            value="Dysmenorrhea"
            hide-details
          ></v-checkbox>

          <v-checkbox
            v-model="usual2"
            label="Headache"
            color="primary"
            value="Headache"
            hide-details
          ></v-checkbox>

          <v-checkbox
            v-model="usual3"
            label="Asthma"
            color="primary"
            value="Asthma"
            hide-details
          ></v-checkbox>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-checkbox
            v-model="usual4"
            label="Heart problems"
            color="primary"
            value="Heart problems"
            hide-details
          ></v-checkbox>

          <v-checkbox
            v-model="usual5"
            label="Stomachache"
            color="primary"
            value="Headache"
            hide-details
          ></v-checkbox>

          <v-checkbox
            v-model="usual6"
            label="Colds/Flu"
            color="primary"
            value="Colds/Flu"
            hide-details
          ></v-checkbox>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-checkbox
            v-model="usual7"
            label="Abdominal pain"
            color="primary"
            value="Abdominal pain"
            hide-details
          ></v-checkbox>

          <v-checkbox
            v-model="usual8"
            label="Seizure disorders"
            color="primary"
            value="Seizure disorders"
            hide-details
          ></v-checkbox>
          <br />
          <v-text-field
            v-model="usual9"
            label="Others (Please specify)"
            outlined
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>
            What physical disabilities are hindering you in your academic and
            extra-curricular activities?
          </h2>
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-radio-group v-model="phy" column>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-radio
                  label="Visual Impairment"
                  color="primary"
                  value="Visual Impairment"
                ></v-radio>

                <v-radio
                  label="Physical deformities caused by accident"
                  color="primary"
                  value="Physical deformities caused by accident"
                ></v-radio>

                <v-radio
                  label="Hearing Impairment"
                  color="primary"
                  value="Hearing Impairment"
                ></v-radio>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-radio label="Polio" color="primary" value="Polio"></v-radio>

                <v-radio
                  label="Cleft palate"
                  color="primary"
                  value="Cleft palate"
                ></v-radio>

                <br />
                <v-text-field
                  v-model="phy2"
                  :rules="[
                    (v) =>
                      v.length <= 45 ||
                      'Fields must be less than 45 characters',
                  ]"
                  label="Others (Please specify)"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-radio label="None" color="primary" value="None"></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="savechanges"
          >
            Save Changes
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      Your changes have been successfully saved!
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "Health",
  data: () => ({
    snackbar: false,
    timeout: 2000,
    valid: true,
    formTitle: "",
    nameRules: [
      (v) => v.length > 0 || "Fields is required",
      (v) => (v && v.length <= 45) || "Fields must be less than 45 characters",
    ],
    ageRules: [
      (v) => v.length > 0 || "Fields is required",
      (v) => (v && v <= 2021) || "Fields must be less than 2 digits",
    ],
    currentYear: new Date().getFullYear(),
    Sick: "Sometimes",
    usual1: "",
    usual2: "",
    usual3: "",
    usual4: "",
    usual5: "",
    usual6: "Colds/Flu",
    usual7: "",
    usual8: "",
    usual9: "",
    phy: "None",
    phy2: "",
  }),
  mounted() {
    this.$api.methods.init();
    this.gethealth();
  },
  methods: {
    savechanges() {
      if (this.$refs.form.validate()) {
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_BASE_URL + "guid/updatehealth",
          headers: {
            Authorization: "Bearer" + localStorage.getItem("usertoken"),
          },
          data: {
            Sick: this.Sick,
            usual1: this.usual1,
            usual2: this.usual2,
            usual3: this.usual3,
            usual4: this.usual4,
            usual5: this.usual5,
            usual6: this.usual6,
            usual7: this.usual7,
            usual8: this.usual8,
            usual9: this.usual9,
            phy: this.phy,
            phy2: this.phy2,
          },
        })
          .then((response) => {
            if (response) {
              this.snackbar = true;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 500) {
                //
              }
            }
          });
      }
    },

    gethealth() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "guid/health",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            //
            this.Sick = response.data[0][0].Sick;

            for (var i = 0; i < response.data[1].length; i++) {
              switch (response.data[1][i].Description) {
                case "Dysmenorrhea":
                  this.usual1 = response.data[1][i].Description;
                  break;
                case "Headache":
                  this.usual2 = response.data[1][i].Description;
                  break;
                case "Asthma":
                  this.usual3 = response.data[1][i].Description;
                  break;
                case "Heart problems":
                  this.usual4 = response.data[1][i].Description;
                  break;
                case "Stomachache":
                  this.usual5 = response.data[1][i].Description;
                  break;
                case "Colds/Flu":
                  this.usual6 = response.data[1][i].Description;
                  break;
                case "Abdominal pain":
                  this.usual7 = response.data[1][i].Description;
                  break;
                case "Seizure disorders":
                  this.usual8 = response.data[1][i].Description;
                  break;
                default:
                  this.usual9 = response.data[1][i].Description;
              }
            }

            var phyItems = [
              "Visual Impairment",
              "Physical deformities caused by accident",
              "Hearing Impairment",
              "Polio",
              "Cleft palate",
            ];
            if (phyItems.indexOf(response.data[2][0].Description) > -1) {
              this.phy = response.data[2][0].Description;
            } else {
              this.phy2 = response.data[2][0].Description;
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
  },
};
</script>

<style>
.container {
  max-width: 80%;
}
</style>